/* eslint-disable max-lines, dot-notation */

import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useState } from "react";
import { useEffectOnce } from "react-use";
import Panel from '../shared/Panel/Panel';
import styles from './Dashboard.module.scss';
import refreshFbPosts from './refreshFbPosts';

interface RestaurantInfo {
	name: string;
	updated: string;
	url: string;
}

const Dashboard = () => {

	const [restaurants, setRestaurants] = useState<RestaurantInfo[]>();

	useEffectOnce(() => {
		fetch(`${process.env.PUBLIC_URL}/links/links.json`)
			.then(async (response) => response.json())
			.then((json: Record<string, Record<string, string>>) => {
				setRestaurants(Object.entries(json).map(([name, info]) => ({
					name,
					updated: info.updated,
					url: info.url
				})));
			})
			.then(() => refreshFbPosts());
	});

	return (
		<Grid container>
			{restaurants?.map((restaurant) => (
				<Grid item sm={12} md={6} key={restaurant.name}>
					<Panel
						ariaLabel={restaurant.name}
						headerLeft={
							<Typography variant="h5">
								<FormattedMessage id={`menu.${restaurant.name}`} />
							</Typography>
						}
						childrenClassNames={styles.panel}
					>
						<FormattedMessage
							id="menu.updated-at"
							values={{ updateTime: restaurant.updated }}
						/>
						<br />
						<br />
						<div
							className="fb-post"
							data-href={restaurant.url}
							data-show-text="true"
						/>
					</Panel>
				</Grid>
			))}
		</Grid>
	);
};

export default Dashboard;
