import * as React from 'react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import styles from './Header.module.scss';

const Header: FunctionComponent = () => (
	<div
		className={styles.header}
	>
		<div className={styles.title} data-cy="title">
			<Typography variant="h3">
				<FormattedMessage id="header.title" />
			</Typography>
		</div>
	</div>
);

export default Header;
