import { useState, useEffect } from 'react';

export interface WindowSize {
	width: number;
	height: number;
}

export function useWindowSize(): WindowSize {
	const isBrowser: boolean = typeof window === 'object';

	const [windowSize, setWindowSize] = useState({
		width: isBrowser ? window.innerWidth : 0,
		height: isBrowser ? window.innerHeight : 0
	});

	useEffect(() => {
		function getSize() {
			return {
				width: isBrowser ? window.innerWidth : 0,
				height: isBrowser ? window.innerHeight : 0
			};
		}

		function handleResize() {
			setWindowSize(getSize());
		}

		if (!isBrowser) {
			return;
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isBrowser]);

	return windowSize;
}
