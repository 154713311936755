import { ThemeOptions } from '@material-ui/core/styles';
import { Color } from '../model/Color';

export const muiStyles: ThemeOptions = {
	palette: {
		common: {
			black: Color.GRAY_DARK,
			white: Color.WHITE
		},
		primary: {
			main: Color.YELLOW,
			light: Color.YELLOW_LIGHT,
			dark: Color.YELLOW_DARK,
			contrastText: Color.GRAY_DARK
		},
		secondary: {
			main: Color.BLUE,
			light: Color.BLUE_LIGHT,
			dark: Color.BLUE_DARK,
			contrastText: Color.WHITE
		},
		error: {
			main: Color.RED,
			light: Color.RED_LIGHT,
			dark: Color.RED_DARK,
			contrastText: Color.WHITE
		},
		grey: {
			'50': '#fafafa',
			'100': '#f5f5f5',
			'200': '#eeeeee',
			'300': '#e0e0e0',
			'400': '#bdbdbd',
			'500': '#9e9e9e',
			'600': '#757575',
			'700': '#616161',
			'800': '#424242',
			'900': '#212121',
			'A100': '#d5d5d5',
			'A200': '#aaaaaa',
			'A400': '#303030',
			'A700': '#616161'
		},
		contrastThreshold: 3,
		tonalOffset: 0.1,
		text: {
			primary: Color.GRAY_DARK,
			secondary: 'rgba(0, 0, 0, 0.4)',
			disabled: 'rgba(0, 0, 0, 0.35)',
			hint: 'rgba(0, 0, 0, 0.35)'
		},
		divider: 'rgba(0, 0, 0, 0.12)',
		background: {
			paper: '#fff',
			default: Color.GRAY_LIGHT
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0)',
			hoverOpacity: 0.06,
			selected: 'rgba(0, 0, 0, 0.14)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)'
		}
	},
	shadows: [
		'none',
		'0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
		'', // Button shadow
		'0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
		'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
		'', // Fab shadow
		'0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
		'0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
		'0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
		'0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
		'0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
		'0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
		'0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
		'0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
		'0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
		'0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
		'0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
		'0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
		'0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
		'0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
	],
	typography: {
		htmlFontSize: 10,
		fontFamily: '"Avenir W01", sans-serif',
		fontSize: 10,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 900,
		h1: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 900,
			fontSize: '2.5rem',
			color: Color.GRAY_DARK,
			lineHeight: 1,
			letterSpacing: '-0.01562em'
		},
		h2: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 800,
			fontSize: '2.35rem',
			color: Color.GRAY_DARK,
			lineHeight: 1,
			letterSpacing: '-0.00833em'
		},
		h3: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 800,
			fontSize: '2.2rem',
			color: Color.GRAY_DARK,
			lineHeight: 1.04,
			letterSpacing: '0em'
		},
		h4: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 400,
			fontSize: '2.1rem',
			color: Color.GRAY_DARK,
			lineHeight: 1.17,
			letterSpacing: '0.00735em'
		},
		h5: {
			fontFamily: '"Avenir", sans-serif',
			fontWeight: 400,
			fontSize: '2rem',
			color: Color.GRAY_DARK,
			lineHeight: 1.33,
			letterSpacing: '0em'
		},
		h6: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 300,
			fontSize: '1.8rem',
			color: Color.BLUE,
			lineHeight: 1.6,
			letterSpacing: '0.0075em'
		},
		subtitle1: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 400,
			fontSize: '1.4rem',
			lineHeight: 1.75,
			letterSpacing: '0.00938em'
		},
		subtitle2: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 500,
			fontSize: '1rem',
			lineHeight: 1.57,
			letterSpacing: '0.00714em'
		},
		body1: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 400,
			fontSize: '1.6rem',
			color: Color.GRAY_DARK,
			lineHeight: 1.5,
			letterSpacing: '0.00938em'
		},
		body2: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 400,
			color: Color.GRAY_DARK,
			fontSize: '1.4rem',
			lineHeight: 1.4,
			letterSpacing: '0.01071em'
		},
		button: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 500,
			fontSize: '1.6rem',
			lineHeight: 1.8,
			textTransform: 'none'
		},
		caption: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: 1.66,
			letterSpacing: '0.03333em'
		},
		overline: {
			fontFamily: '"Avenir W01", sans-serif',
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: 2.66,
			letterSpacing: '0.1em',
			textTransform: 'uppercase'
		}
	},
	shape: {
		borderRadius: 8
	},
	transitions: {
		easing: {
			easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
			easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
			easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
			sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
		},
		duration: {
			shortest: 150,
			shorter: 200,
			short: 250,
			standard: 300,
			complex: 375,
			enteringScreen: 225,
			leavingScreen: 195
		}
	},
	zIndex: {
		mobileStepper: 1000,
		appBar: 1100,
		drawer: 1200,
		modal: 1300,
		snackbar: 1400,
		tooltip: 1500
	}
};
