import * as React from 'react';
import logo from './logo-ct.svg';
import styles from './Logo.module.scss';

export interface LogoProps {
	onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
	text?: string;
}

/**
 * Logo component.
 *
 * @version 1.0.0
 */
const Logo: React.FunctionComponent<LogoProps> = ({ onClick, text }) => (
	<div className={styles.container}>
		<img
			src={logo}
			className={styles.logo}
			alt="CT logo"
			onClick={onClick}
		/>
		{text && (
			<>
				<div className={styles.separator} />
				<div>
					<div className={styles.text}>{text}</div>
				</div>
			</>
		)}
	</div>
);

export default Logo;
