import { Color } from '../model/Color';
import { ThemeOptionsWithSkeleton } from './mui.config';

export const muiOverrides: ThemeOptionsWithSkeleton = {
	overrides: {
		// @see https://material-ui.com/components/buttons/
		MuiButton: {
			root: {
				fontFamily: '"Avenir W01", sans-serif',
				borderRadius: '1.7rem',
				padding: '0.25rem 2.5rem',
				minWidth: '0px',
				fontWeight: 'normal',
				fontSize: '1.7rem',
				zIndex: 99
			},
			fullWidth: {
				width: '100% !important'
			},
			outlined: {
				'&.Mui-disabled': {
					border: 'unset'
				}
			}
		},
		// @see https://material-ui.com/components/fab/
		MuiFab: {
			root: {
				width: '3.6rem',
				height: '3.6rem'
			},
			primary: {
				color: Color.WHITE
			},
			secondary: {
				color: Color.WHITE
			}
		},
		// @see https://material-ui.com/components/text-fields/
		MuiTextField: {
			root: {
				marginTop: '.75rem',
				fontSize: '2rem'
			}
		},
		// @see https://material-ui.com/api/filled-input/
		MuiFilledInput: {
			root: {
				backgroundColor: Color.GRAY_LIGHT,
				borderRadius: '0.8rem !important'
			}
		},
		// @see https://material-ui.com/api/form-label/
		MuiFormLabel: {
			root: {
				'fontSize': '1.4rem',
				'color': Color.GRAY,
				'&$focused': {
					color: Color.BLUE
				}
			}
		},
		// @see https://material-ui.com/api/input-base/
		MuiInputBase: {
			root: {
				fontSize: '1.5rem',
				fontFamily: 'Avenir W01',
				fontWeight: 300
			},
			input: {
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color'
				}
			}
		},
		// @see https://material-ui.com/api/touch-ripple/
		MuiTouchRipple: {
			child: {
				backgroundColor: 'rgba(0,0,0,0.25)'
			}
		},
		// @see https://material-ui.com/api/svg-icon/
		MuiSvgIcon: {
			root: {
				color: 'inherit'
			},
			colorSecondary: {
				color: Color.WHITE
			},
			colorAction: {
				color: Color.BLUE_DARK
			},
			fontSizeSmall: {
				fontSize: 'inherit'
			}
		},
		// @see https://material-ui.com/api/snackbar-content/
		MuiSnackbarContent: {
			message: {
				fontSize: '1.5rem',
				fontFamily: 'Avenir W01'
			}
		},
		// @see https://material-ui.com/api/checkbox/
		MuiCheckbox: {
			root: {}
		},
		// @see https://material-ui.com/api/table-cell
		MuiTableCell: {
			root: {
				borderBottom: `1px solid ${Color.GRAY_LIGHT}`,
				fontSize: '1.5rem'
			},
			head: {
				fontSize: '1.5rem',
				lineHeight: '2rem',
				fontWeight: 400,
				color: Color.BLACK
			},
			body: {
				fontSize: '1.4rem',
				color: Color.GRAY
			},
			footer: {
				borderBottom: 'none',
				fontSize: '1.5rem'
			}
		},
		// @see https://material-ui.com/api/table-sort-label
		MuiTableSortLabel: {
			root: {
				marginTop: '-4px'
			},
			iconDirectionAsc: {
				color: Color.GRAY
			},
			iconDirectionDesc: {
				color: Color.GRAY
			}
		},
		// @see https://material-ui.com/api/table-row
		MuiTableRow: {
			root: {
				'&:hover': {
					'backgroundColor': Color.GRAY_TRANSPARENT,
					'& > td': {
						color: `${Color.BLACK} !important`
					}
				},
				'footer': {
					'&:hover': {
						backgroundColor: 'inherit'
					}
				}
			}
		},
		// @see https://material-ui.com/api/tooltip/
		MuiTooltip: {
			tooltip: {
				fontSize: '1.4rem',
				fontWeight: 300,
				backgroundColor: Color.WHITE,
				color: Color.GRAY_DARK,
				boxShadow:
					'0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12)',
				padding: '1rem',
				textAlign: 'justify'
			},
			popper: {
				zIndex: 9999
			}
		},
		// @see https://material-ui.com/api/popover
		MuiPopover: {
			paper: {
				fontSize: '1.4rem',
				fontWeight: 300,
				backgroundColor: Color.WHITE,
				color: Color.GRAY_DARK,
				boxShadow:
					'0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12)',
				padding: '1rem',
				textAlign: 'justify'
			}
		},
		// @see https://material-ui.com/api/menu-item/
		MuiMenuItem: {
			root: {
				fontSize: '1.6rem'
			}
		},
		// @see https://material-ui.com/api/skeleton/
		MuiSkeleton: {
			root: {
				backgroundColor: Color.GRAY_LIGHT
			}
		},
		// @see https://material-ui.com/api/dialog-title/
		MuiDialogTitle: {
			root: {
				fontSize: '2rem'
			}
		},
		// @see https://material-ui.com/api/list-item-text
		MuiListItemText: {
			root: {
				fontSize: '1.6rem'
			}
		},
		// @see https://material-ui.com/api/input-adornment
		MuiInputAdornment: {
			root: {
				color: 'darkgray'
			},
			positionStart: {
				marginTop: 'unset !important'
			}
		},
		MuiToolbar: {
			regular: {
				height: '60px'
			}
		},
		// @see https://material-ui.com/api/chip/
		MuiChip: {
			root: {
				height: '24px',
				fontSize: '1.1rem',
				backgroundColor: Color.GRAY_LIGHT
			}
		},
		// @see https://material-ui.com/api/badge/
		MuiBadge: {
			badge: {
				border: `2px solid ${Color.GRAY_LIGHT}`
			},
			colorPrimary: {
				color: Color.WHITE
			}
		},
		// @see https://material-ui.com/api/toggle-button/
		MuiToggleButton: {
			root: {
				'&.Mui-selected': {
					'color': Color.WHITE,
					'borderColor': Color.BLUE,
					'backgroundColor': Color.BLUE,
					'&&:hover': {
						borderColor: Color.BLUE_DARK,
						backgroundColor: Color.BLUE_DARK
					}
				}
			}
		},
		// @see https://material-ui.com/api/button-group/
		MuiButtonGroup: {
			root: {
				padding: '5px',
				backgroundColor: Color.GRAY_LIGHT
			},
			grouped: {
				'borderRadius': '0.5rem !important',
				'border': 'unset',
				'fontSize': '1.5rem',
				'padding': '2px 15px',

				'&:not(:last-child)': {
					marginRight: '5px'
				}
			}
		}
	},
	props: {
		// @see https://material-ui.com/components/buttons/
		MuiButton: {
			variant: 'contained',
			color: 'secondary'
		},
		// @see https://material-ui.com/components/text-fields/
		MuiTextField: {
			variant: 'filled',
			margin: 'dense'
		},
		// @see https://material-ui.com/api/filled-input/
		MuiFilledInput: {
			disableUnderline: true
		},
		// @see https://material-ui.com/api/checkbox/
		MuiCheckbox: {},
		// @see https://material-ui.com/api/circular-progress/
		MuiCircularProgress: {
			color: 'secondary',
			size: 30
		},
		// @see https://material-ui.com/api/grid/
		MuiGrid: {
			spacing: 3
		}
	}
};
