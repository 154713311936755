import * as React from 'react';
import { ReactNode } from 'react';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useIntl } from 'react-intl';
import styles from './Panel.module.scss';

interface PanelProps {
	ariaLabel: string;
	headerLeft?: ReactNode;
	headerRight?: ReactNode;
	isTransparent?: boolean;
	isFullHeight?: boolean;
	withPadding?: boolean;
	fitContent?: boolean;
	children: ReactNode;
	classNames?: string;
	childrenClassNames?: string;
	unreliableWarning?: boolean;
	exportButton?: ReactNode;
}

const Panel: React.FunctionComponent<PanelProps> = ({
	ariaLabel,
	headerLeft,
	headerRight,
	isTransparent = false,
	withPadding = true,
	isFullHeight = false,
	children,
	classNames = '',
	childrenClassNames = '',
	fitContent,
	unreliableWarning,
	exportButton
}) => {
	const { formatMessage } = useIntl();
	const renderUnreliableWarning = () => (
		<Tooltip title={formatMessage({ id: 'statistics.segment.unreliable' })}>
			<WarningIcon color="primary" fontSize="large" className={styles.warningIcon} />
		</Tooltip>
	);

	const Header: ReactNode = (headerLeft || headerRight || unreliableWarning) && (
		<div className={styles.header}>
			<div className={styles.title}>
				{headerLeft && <div className={styles.headerLeft}>{headerLeft}</div>}
				{headerRight && <div className={styles.headerRight}>{headerRight}</div>}
			</div>
			{unreliableWarning && <div className={styles.warning}>{renderUnreliableWarning()}</div>}
			{exportButton && <div className={styles.exportButton}>{exportButton}</div>}
		</div>
	);

	return (
		<section
			aria-label={ariaLabel}
			className={cx(styles.panel, {
				[styles.transparent]: isTransparent,
				[styles.fullHeight]: isFullHeight,
				[styles.fitContent]: fitContent,
				[classNames]: !!classNames
			})}
		>
			{Header}
			<div
				className={cx(styles.content, {
					[styles.contentWithPadding]: withPadding,
					[styles.fullHeight]: isFullHeight,
					[childrenClassNames]: !!childrenClassNames
				})}
			>
				{children}
			</div>
		</section>
	);
};

export default Panel;
