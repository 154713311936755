import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import './index.scss';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Powercharts from 'fusioncharts/fusioncharts.powercharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as serviceWorker from './serviceWorker';
import englishTranslations from './core/translations/en.json';
import { muiConfig } from './core/styles/mui.config';
import SuspenseViewBoundary from "./shared/SuspenseViewBoundary/SuspenseViewBoundary";
import Dashboard from "./dashboard/Dashboard";
import AuthLayout from "./core/components/AuthLayout/AuthLayout";


// @ts-ignore
// FusionCharts.options.creditLabel = false;
FusionCharts.options.license({
	key: process.env.REACT_APP_FUSIONCHARTS_LICENSE_KEY,
	creditLabel: false
});
ReactFC.fcRoot(FusionCharts, Charts, Powercharts, FusionTheme);

const cache = createIntlCache();
export const intl = createIntl(
	{
		locale: 'en',
		messages: englishTranslations
	},
	cache
);

// When adding languages check whether a single instance of Intl needs to be created and passed to
// The provider so it can also be exported. For now, outside components, a separate instance is created with createIntl

ReactDOM.render(
	<IntlProvider locale="en" messages={englishTranslations}>
		<StylesProvider injectFirst>
			<ThemeProvider theme={createMuiTheme(muiConfig)}>
				<SuspenseViewBoundary>
					<AuthLayout>
						<SuspenseViewBoundary withPadding>
							<Dashboard />
						</SuspenseViewBoundary>
					</AuthLayout>
				</SuspenseViewBoundary>
				<img src="//pixel.onaudience.com/?event=137105424" width="0" height="0" alt="pixel" />
			</ThemeProvider>
		</StylesProvider>
	</IntlProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// Unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
