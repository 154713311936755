/**
 * The color palette used in the project. Used mainly to override the
 * material-ui library configuration.
 */
export enum Color {
	WHITE = '#FFFFFF',
	BLACK = '#000000',
	GRAY = '#999FA8',
	GRAY_LIGHT = '#EFF1F3',
	GRAY_DARK = '#303235',
	GRAY_TRANSPARENT = 'rgba(0,0,0,.0375)',
	YELLOW = '#F8C112',
	YELLOW_LIGHT = '#F8C112',
	YELLOW_DARK = '#F8C112',
	BLUE = '#00ACEC',
	BLUE_LIGHT = '#86deff',
	BLUE_DARK = '#009DD7',
	BLUE_DARKER = '#086788',
	BLUE_LIGHTER = '#bbe5f9',
	BLUE_LIGHTEST = '#ddf3ff',
	BLUE_MIDDLE = '#37bdee',
	OAN_VIOLET = '#344185',
	ORANGE = '#f37748',
	RED = '#E92E55',
	RED_LIGHT = '#ffc0cc',
	RED_DARK = '#E92E55',
	GREEN = '#32AC15',
	GREEN_LIGHT = '#bce2b4',
	PINK = '#F588D8'
}
