import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import './Footer.scss';
import { FunctionComponent } from 'react';
import { CURRENT_YEAR } from '../../utilities/constants';

const Footer: FunctionComponent = () => (
	<footer className="Footer">
		<div className="Footer__copyrights">
			<FormattedMessage
				id="footer.copyright.text"
				values={{ currentYear: CURRENT_YEAR }}
			/>
			<a href="https://www.cloudtechnologies.pl">
				<FormattedMessage id="footer.copyright.link" />
			</a>
		</div>
	</footer>
);

export default Footer;
