import { ThemeOptions } from '@material-ui/core/styles';
import { SkeletonClassKey } from '@material-ui/lab/Skeleton';
import { ToggleButtonClassKey } from '@material-ui/lab/ToggleButton';
import { StyleRules } from '@material-ui/core/styles';
import { muiStyles } from './mui.styles';
import { muiOverrides } from './mui.overrides';

export type ThemeOptionsWithSkeleton = ThemeOptions & {
	overrides: {
		MuiSkeleton: Partial<StyleRules<SkeletonClassKey>>;
		MuiToggleButton?: Partial<StyleRules<ToggleButtonClassKey>>;
	};
};

export const muiConfig: ThemeOptionsWithSkeleton = {
	...muiStyles,
	...muiOverrides
};
