import * as React from 'react';
import { FunctionComponent, lazy, useEffect } from "react";
import cx from 'classnames';
import { FormattedMessage, useIntl } from "react-intl";
import { Button, ButtonBase, ClickAwayListener, IconButton, Tooltip } from "@material-ui/core";
import { Home, Lock, LockOpen, Menu } from "@material-ui/icons";
import Logo from '../../../shared/Logo/Logo';
import { SidebarRoute } from '../../router/Route';
import { useWindowSize } from '../../hooks/useWindowSize';
import styles from './Sidebar.module.scss';

const SIDEBAR_MOBILE_VERSION_BREAKPOINT = 960;

const AsyncDmpDashboard = lazy(async () => import('../../../dashboard/Dashboard'));

export interface SidebarProps {
	isOpen: boolean;
	isLocked: boolean;
	onIsOpenChange: (isOpen: boolean) => void;
	onIsLockedChange: (isLocked: boolean) => void;
}

const Sidebar: FunctionComponent<SidebarProps> = ({
	isOpen,
	isLocked,
	onIsOpenChange,
	onIsLockedChange
}) => {
	const { formatMessage } = useIntl();

	const ROUTES: SidebarRoute[] = [{
		isDisplayedInSidebar: true,
		icon: <Home />,
		path: '/',
		component: AsyncDmpDashboard,
		name: formatMessage({ id: 'page.dashboard' })
	}];

	const isDesktop = window.innerWidth > SIDEBAR_MOBILE_VERSION_BREAKPOINT;

	const toggleIsOpen = () => {
		onIsOpenChange(!isOpen);
	};

	const closeSidebarOnMobile = () => {
		if (!isDesktop) {
			onIsOpenChange(false);
		}
	};

	const toggleIsLocked = () => {
		onIsLockedChange(!isLocked);
	};

	const { width: screenWidth } = useWindowSize();
	useEffect(() => {
		if (!isLocked && screenWidth && screenWidth <= SIDEBAR_MOBILE_VERSION_BREAKPOINT) {
			onIsLockedChange(true);
		}
	}, [screenWidth, isLocked, onIsLockedChange]);

	return (
		<ClickAwayListener onClickAway={closeSidebarOnMobile}>
			<div
				className={cx(styles.sidebar, {
					[styles.sidebarClosed]: !isOpen,
					[styles.sidebarUnlocked]: !isLocked
				})}
			>
				<div className={styles.top}>
					<div className={styles.logo}>
						<Logo />
					</div>
					<div className={styles.toggle}>
						<IconButton onClick={toggleIsOpen}>
							<Menu />
						</IconButton>
					</div>
				</div>
				<div className={styles.listWrapper}>
					<ul className={styles.list} style={{ position: 'relative' }}>
						{ROUTES.map((route: SidebarRoute) => (
							<Tooltip
								title={isOpen ? '' : route.name}
								placement="right"
								key={`sidebar-item-${route.name}`}
							>
								<li>
									<a
										className={cx(styles.link, styles.activeLink)}
										onClick={closeSidebarOnMobile}
									>
										<ButtonBase
											focusRipple
											classes={{ root: styles.linkButton }}
										>
											<span className={styles.icon}>{route.icon}</span>
											<span className={styles.label}>{route.name}</span>
										</ButtonBase>
									</a>
								</li>
							</Tooltip>
						))}
					</ul>
				</div>

				<div className={styles.spacer} />
				<div className={styles.bottomWrapper}>
					<div className={styles.bottom}>
						<Button
							variant="outlined"
							classes={{
								root: cx(styles.lockButton, {
									[styles.lockButtonClosed]: !isOpen
								})
							}}
							onClick={toggleIsLocked}
						>
							{isLocked ? <Lock /> : <LockOpen />}
							<div className={styles.lockButtonLabel}>
								{isLocked ? (
									<FormattedMessage id="sidebar.locked" />
								) : (
									<FormattedMessage id="sidebar.unlocked" />
								)}
							</div>
						</Button>
					</div>
				</div>
			</div>
		</ClickAwayListener>
	);
};

export default Sidebar;
