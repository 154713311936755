import * as React from 'react';
import { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import { StorageKey } from '../../model/StorageKey';
import useLocalStorage from '../../hooks/localStorage';
import styles from './AuthLayout.module.scss';

/**
 * Layout for every page after login.
 *
 * @version 1.0.0
 */
const AuthLayout: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const [isOpen, setIsOpen] = useLocalStorage<boolean>(StorageKey.IS_SIDEBAR_OPEN, false);
	const [isLocked, setIsLocked] = useLocalStorage<boolean>(StorageKey.IS_SIDEBAR_LOCKED, true);

	return (
		<div
			className={cx(styles.layout, {
				[styles.layoutSidebarUnlocked]: !isLocked,
				[styles.layoutSidebarClosed]: isLocked && !isOpen,
				[styles.layoutSidebarOpen]: isLocked && isOpen
			})}
		>
			<Sidebar
				isOpen={Boolean(isOpen)}
				isLocked={Boolean(isLocked)}
				onIsOpenChange={setIsOpen}
				onIsLockedChange={setIsLocked}
			/>
			<section className={styles.container}>
				<header>
					<Header />
				</header>
				<div className={styles.content}>{children}</div>
				<Footer />
			</section>
		</div>
	);
};

export default AuthLayout;
