import React, { ReactNode, Suspense } from 'react';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';
import styles from './SuspenseViewBoundary.module.scss';

export interface ViewBoundaryProps {
	withPadding?: boolean;
	children: ReactNode;
}

export const SuspenseViewBoundary: React.FunctionComponent<ViewBoundaryProps> = ({
	withPadding = false,
	children
}) => (
	<div
		className={cx(styles.ViewBoundary, {
			[styles.addPadding]: withPadding
		})}
	>
		<Suspense fallback={<CircularProgress classes={{ root: styles.center }} />}>
			{children}
		</Suspense>
	</div>
);
export default SuspenseViewBoundary;
